(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["cs"] = {
            name: "cs",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": " ",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "",
                    abbr: "",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "Kč"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["neděle","pondělí","úterý","středa","čtvrtek","pátek","sobota"],
                        namesAbbr: ["ne","po","út","st","čt","pá","so"],
                        namesShort: ["ne","po","út","st","čt","pá","so"]
                    },
                    months: {
                        names: ["leden","únor","březen","duben","květen","červen","červenec","srpen","září","říjen","listopad","prosinec"],
                        namesAbbr: ["led","úno","bře","dub","kvě","čvn","čvc","srp","zář","říj","lis","pro"]
                    },
                    AM: ["dop.","dop.","DOP."],
                    PM: ["odp.","odp.","ODP."],
                    patterns: {
                        d: "dd.MM.yyyy",
                        D: "dddd d. MMMM yyyy",
                        F: "dddd d. MMMM yyyy H:mm:ss",
                        g: "dd.MM.yyyy H:mm",
                        G: "dd.MM.yyyy H:mm:ss",
                        m: "d. MMMM",
                        M: "d. MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "H:mm",
                        T: "H:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": ".",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
